import { formatWithoutRounding } from "../global/apps/mixins/productPrice.mixin";
import { Money } from "../global/apps/services/models/cart.models";
import {
    DeliveryLocation,
    DeliveryMethod,
} from "../global/apps/utils/API/Delivery/deliveryAPISchema";
import {
    PaymentMethodViewModel,
    AddPaymentMethodRequestDto,
} from "../global/apps/utils/API/Payment/paymentAPISchema";
import { formatCurrency } from "../global/apps/utils/currencyHelpers";

export const DeliveryMethods = {
    MyPack: "MyPack",
    Porterbuddy: "Porterbuddy",
    HomeDelivery: "HomeDelivery",
    PickUpInStore: "PickUpInStore",
} as const;

/**
 * CheckoutEvents is an object that defines the event names used in the checkout event bus.
 */
export const AfterpayMethods = {
    Account: "account",
    Installment: "installment",
} as const;

const validPaymentImageIds = [
    "riverty_invoice",
    "vipps",
    "nets",
    "riverty_installment",
];

/**
 * Checks if an order qualifies for free shipping.
 * To qualify for free shipping the freeShippingActive flag must be true and the order value must be greater than or equal to the freeShippingLimit.
 *
 * @param freeShippingActive - Indicates if free shipping is active.
 * @param freeShippingLimit - The minimum order value required for free shipping.
 * @param subTotal - The total order value.
 * @returns A boolean value indicating if the order qualifies for free shipping.
 */
export const qualifiesForFreeShipping = (
    freeShippingActive: boolean,
    freeShippingLimit: Money,
    subTotal: Money,
): boolean => {
    return freeShippingActive && freeShippingLimit.amount <= subTotal.amount;
};

/**
 * Calculates the remaining amount needed to qualify for free shipping, if a free shipping treshold is active
 *
 * @returns {Money | undefined} The remaining amount needed to qualify for free shipping, or undefined if a free shipping treshold is not active
 */
export const amountNeededToQualifyForFreeShipping = (
    freeShippingActive?: boolean,
    freeShippingLimit?: Money,
    value?: Money,
): Money | undefined => {
    if (
        !freeShippingActive ||
        !value ||
        !freeShippingLimit ||
        freeShippingLimit.amount <= value.amount
    )
        return;

    return {
        amount: freeShippingLimit.amount - value.amount,
        currency: value.currency,
    };
};

/**
 * Computes and returns the formatted text for shipping cost.
 *
 * @returns {string} The formatted shipping cost text.
 */
export const shippingCostFormatted = (
    freeShippingActive: boolean,
    freeShippingLimit: Money,
    value: Money,
    selectedShippingMethodPrice: Money,
): string => {
    if (
        (freeShippingActive && freeShippingLimit.amount <= value.amount) ||
        !selectedShippingMethodPrice.amount
    )
        return "Fri frakt";

    if (selectedShippingMethodPrice?.amount) {
        return `${formatCurrency(selectedShippingMethodPrice.currency)} ${formatWithoutRounding(selectedShippingMethodPrice.amount)}`;
    }

    return "Beregnes senere";
};

/**
 * Returns a formatted string representing the delivery window timestamp.
 *
 * @param date - The date object representing the delivery window timestamp.
 * @returns A string in the format "HH:MM" representing the delivery window timestamp.
 */
export const deliveryWindowTimeStamp = (date: Date) => {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`;
};

/**
 * Formats the given date and returns the weekday in no-NB.
 *
 * @param date - The date to format.
 * @returns The formatted weekday.
 */
export const formatDay = (date: Date) => {
    if (isToday(date)) return "I dag";

    return date.toLocaleDateString("no-NB", {
        weekday: "long",
        day: "numeric",
        month: "long",
    });
};

/**
 * Checks if a given date is today.
 *
 * @param {Date} date - The date to check.
 * @returns {boolean} - Returns true if the date is today, false otherwise.
 */
const isToday = (date: Date) => {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

/**
 * Returns the formatted address details for a given delivery location.
 *
 * @param {DeliveryLocation} location - The delivery location object.
 * @returns {string} - The address details.
 */
export const formatDeliveryLocationAddress = (
    location: DeliveryLocation,
): string => {
    return `${location.deliveryAddress?.trim()}, ${location.postalCode} ${location.city}`;
};

/**
 * Returns the logo name for a given delivery method.
 * The purpose of the logo name is to identify the image filename
 *
 * @param deliveryMethod - The delivery method object.
 * @returns The logo name corresponding to the delivery method.
 */
export const LogoNameForDeliveryMethod = (
    deliveryMethod: DeliveryMethod,
): string => {
    switch (deliveryMethod.name) {
        case "Porterbuddy":
            return "porterbuddy";
        case "MyPack":
        case "HomeDelivery":
            return "postnord";
        default:
            return "";
    }
};

/**
 * Returns the logo name for a given payment method image ID.
 * The purpose of the logo name is to identify the image filename
 *
 * @param imageId The ID of the payment method image.
 * @returns The logo name for the payment method.
 */
export const logoNameForPaymentMethod = (imageId: string): string => {
    if (!imageId || !validPaymentImageIds.find((id) => id === imageId)) {
        return "";
    }

    return `payment_${imageId}`;
};

/**
 * Returns the CSS classname for a given payment method ID.
 *
 * @param id - The payment method ID.
 * @returns The CSS class name for the payment method.
 */
export const paymentClass = (id: string): string => {
    const prefix = "checkout-payment__";
    switch (id) {
        case "nets":
            return `${prefix}nets`;
        case "vipps":
            return `${prefix}vipps`;
        case "afterpay_invoice":
            return `${prefix}invoice`;
        case "afterpay_installment":
            return `${prefix}installment`;
        default:
            return "";
    }
};

/**
 * Checks if the given payment method has Afterpay installment information.
 *
 * @param paymentMethod - The payment method to check.
 * @returns A boolean indicating whether the payment method has Afterpay installment information.
 */
export const hasAfterpayInstallmentInfo = (
    paymentMethod: PaymentMethodViewModel,
) => {
    return !!paymentMethod?.afterPayViewModel?.installmentInfo;
};

/**
 * Checks if the given payment method has Afterpay methods available.
 *
 * @param paymentMethod - The payment method to check.
 * @returns A boolean indicating whether Afterpay methods are available.
 */
export const hasAfterpayMethods = (
    paymentMethod: PaymentMethodViewModel,
): boolean => {
    return !!(
        paymentMethod?.afterPayViewModel?.accountInfo ||
        paymentMethod?.afterPayViewModel?.installmentInfo
    );
};

/**
 * Returns the first Afterpay method based on the provided payment method.
 *
 * @param paymentMethod - The payment method view model.
 * @returns The first Afterpay method ("Account" or "Installment"), or an empty string if no Afterpay method is found.
 */
export const getFirstAfterpayMethod = (
    paymentMethod: PaymentMethodViewModel,
): string => {
    if (paymentMethod?.afterPayViewModel?.accountInfo)
        return AfterpayMethods.Account;
    if (paymentMethod?.afterPayViewModel?.installmentInfo)
        return AfterpayMethods.Installment;

    return "";
};

/**
 * Retrieves a payment method from an array of payment methods based on the selected system keyword.
 *
 * @param paymentMethods - An array of PaymentMethodViewModel objects representing the available payment methods.
 * @param selectedSystemKeyword - The system keyword of the selected payment method.
 * @returns The PaymentMethodViewModel object that matches the selected system keyword, or undefined if no match is found.
 */
export const getPaymentMethodBySystemKeyword = (
    paymentMethods: PaymentMethodViewModel[],
    selectedSystemKeyword: string,
) => {
    return paymentMethods.find(
        (method) => method.systemKeyword === selectedSystemKeyword,
    );
};

/**
 * Retrieves the value of the first installment from the given payment method.
 *
 * @param paymentMethod - The payment method view model.
 * @returns The value of the first installment, or undefined if it is not available.
 */
export const getFirstInstallmentValue = (
    paymentMethod: PaymentMethodViewModel,
) => {
    if (!paymentMethod) return;

    return paymentMethod?.afterPayViewModel?.installmentInfo
        ?.installmentListItems?.[0]?.value;
};

/**
 * Returns the payment body based on the provided payment method and optional parameters.
 *
 * @param paymentMethod - The payment method view model.
 * @param personIdentifier - The person identifier (optional).
 * @param selectedAfterPayInstallmentOption - The selected AfterPay installment option (optional).
 * @param selectedAfterPayInstallmentPlan - The selected AfterPay installment plan (optional).
 * @returns The payment body object.
 */
export const getPaymentBody = (
    paymentMethod: PaymentMethodViewModel,
    personIdentifier?: string,
    selectedAfterPayInstallmentOption?: string,
    selectedAfterPayInstallmentPlan?: string,
) => {
    const body: AddPaymentMethodRequestDto = {};

    if (paymentMethod.requirePersonIdentifier) {
        body.personIdentifier = personIdentifier;
    }

    if (paymentMethod.isAfterPayInstallment) {
        body.selectedAfterPayInstallmentOption =
            selectedAfterPayInstallmentOption;
    }

    if (selectedAfterPayInstallmentOption === AfterpayMethods.Installment) {
        body.selectedAfterPayInstallmentPlan = selectedAfterPayInstallmentPlan;
    }

    return body;
};

/**
 * Function that returns the selected delivery method.
 *
 * @param {Array} deliveryMethods - The array of delivery methods.
 * @param {string} selectedDeliveryMethodId - The ID of the selected delivery method.
 * @returns {any} The selected delivery method.
 */
export const getSelectedDeliveryMethod = (
    deliveryMethods: DeliveryMethod[],
    selectedDeliveryMethodId: string,
) => {
    return deliveryMethods?.find(
        (method) => method.id === selectedDeliveryMethodId,
    );
};

/**
 * Sets information errors based on the provided errors.
 *
 * @param errors - The errors object containing the error messages.
 * @param informationErrors - The information errors object to be updated.
 */
export const setInformationErrors = (errors, informationErrors) => {
    clearErrors(informationErrors);

    errors.errors.forEach(
        (issue: { path: string | number; message: string }) => {
            informationErrors[issue.path].push(issue.message);
        },
    );
};

/**
 * Clears any errors in the error object.
 */
export const clearErrors = (errorObject) => {
    Object.keys(errorObject).forEach((key) => (errorObject[key] = []));
};  

/**
 * Retrieves the first delivery location from the given delivery method.
 *
 * @param deliveryMethod - The delivery method object.
 * @returns The first delivery location, or undefined if no delivery locations are available.
 */
export const getFirstDeliveryLocation = (deliveryMethod: DeliveryMethod) => {
    return deliveryMethod?.deliveryLocations?.[0];
};

/**
 * Retrieves the first delivery window from the given delivery method.
 * @param deliveryMethod - The delivery method object.
 * @returns The first delivery window, or undefined if no delivery windows are available.
 */
export const getFirstDeliveryWindow = (deliveryMethod: DeliveryMethod) => {
    return deliveryMethod?.deliveryWindows?.[0];
};

/**
 * Checks if a delivery method has delivery locations.
 *
 * @param deliveryMethod - The delivery method to check.
 * @returns A boolean indicating whether the delivery method has delivery locations.
 */
export const hasDeliveryLocations = (
    deliveryMethod: DeliveryMethod,
): boolean => {
    return !!deliveryMethod?.deliveryLocations?.length;
};

/**
 * Checks if a delivery method has delivery windows.
 *
 * @param deliveryMethod - The delivery method to check.
 * @returns A boolean indicating whether the delivery method has delivery windows.
 */
export const hasDeliveryWindows = (deliveryMethod: DeliveryMethod): boolean | undefined => {
    return !!deliveryMethod?.deliveryWindows?.length;
};

/**
 * Converts the first character of a string to uppercase and the rest to lowercase.
 *
 * @param text - The input string.
 * @returns The converted string.
 */
export const sentenceCase = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
};
