export enum IconSizes {
    Tiny = "tiny",
    Small = "small",
    Smaller = "smaller",
    MiniCartEmpty = "minicart-empty",
}

export enum IconTypes {
    Search = "search",
    Profile = "profile",
    Close = "close",
    Like = "like",
    Bag = "bag",
    BagEmpty = "bag-empty",
    Heart = "heart",
    Trashcan = "trashcan",
}
