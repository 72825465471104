<template>
  <div class="product-list" @scrollIntoView="handleScrollIntoView">
    <!-- Heading -->
    <h2 v-if="showComponent" class="product-list__heading">
      {{ heading }}
    </h2>
    <!-- Result -->
    <ul
      v-if="showComponent"
      id="category-list"
      class="product-list__items product-list__items--auto-width"
      :class="{
        'product-list__items--column': showAsColumnOnMobile === 'True',
      }"
      :data-layout="limit"
    >
      <template
        v-for="productGroup in productsResult?.primaryList?.productGroups"
      >
        <template v-if="productGroup.products && productGroup.products[0]">
          <li :key="`${productGroup.key}-${productGroup.products[0].key}`">
            <ProductTeaser
              :product-group="productGroup"
              :product="productGroup.products[0]"
              :is-user-authenticated="isUserAuthenticated"
              :is-favorite-form-hidden="isFavoriteFormHidden"
              :favorites="favorites"
              :is-loading-favorites="isLoadingFavorites"
              :use-gtm-tracking="true"
              :user-id="''"
              @toggleFavorite="toggleFavorite($event)"
            />
          </li>
        </template>
      </template>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from "vue";
import { useLazy } from "../../mixins/lazy";
import { getFavorites, toggleFavorite } from "../../mixins/favorites";
import SearchService, { ProductsResult } from "../../services/search.service";
import ProductTeaser from "../../components/productTeaser/productTeaser.vue";

const props = defineProps<{
  heading: string;
  baseUrlPath: string;
  blockId: string;
  apiUrl: string;
  userAuthenticated: string;
  favoriteFormHidden: string;
  lazy: string;
  limit: number;
  showAsColumnOnMobile: string;
}>();

const isLoading = ref(false);
const hasFetched = ref(false);
const productsResult = ref<ProductsResult | null>(null);
const isLoadingFavorites = ref(false);
const favorites = ref(null as Array<string> | null);

// Computed properties
const isFavoriteFormHidden = computed(
  () => props.favoriteFormHidden === "True",
);
const isUserAuthenticated = computed(() => props.userAuthenticated === "True");
const isLazy = computed(() => props.lazy === "True");

const showComponent = computed(() => {
  return (
    productsResult.value !== null &&
    (productsResult.value?.primaryList?.productGroups?.length ?? 0) > 0
  );
});

const fetch = () => {
  if (hasFetched.value) return;

  isLoading.value = true;

  SearchService.products(props.apiUrl, props.baseUrlPath, props.limit)
    .then((response) => {
      hasFetched.value = true;
      productsResult.value = response.data;
    })
    .catch((error) => {
      isLoading.value = false;
      console.error(error);
    });
};

const handleScrollIntoView = () => {
  if (isLazy.value && !hasFetched.value) {
    useLazy(fetch);
  }
};

onBeforeMount(() => {
  handleScrollIntoView();
});

onMounted(() => {
  if (!isLazy.value) {
    fetch();
  }

  if (isUserAuthenticated.value) {
    isLoadingFavorites.value = true;
    getFavorites()
      .then((response) => {
        favorites.value = response?.data.map((i) => String(i)) || null;
      })
      .finally(() => (isLoadingFavorites.value = false));
  }
});
</script>
