import { defineStore } from "pinia";
import {
    DeliveryLocation,
    DeliveryMethod,
    DeliveryModel,
    DeliveryWindow,
} from "Scripts/global/apps/utils/API/Delivery/deliveryAPISchema";

export const useDeliveryInformationStore = defineStore("deliveryInformation", {
    state: () => ({
        deliveryInformation: null as DeliveryModel | null | undefined,
        selectedDeliveryMethod: null as DeliveryMethod | null | undefined,
        selectedDeliveryLocation: null as DeliveryLocation | null | undefined,
        selectedDeliveryWindow: undefined as DeliveryWindow | undefined,
        errors: {
            deliveryMethod: [] as string[],
            deliveryWindow: [] as string[],
            deliveryLocation: [] as string[],
        },
    }),
    actions: {
        setSelectedDeliveryMethod(
            deliveryMethod: DeliveryMethod | null | undefined,
        ) {
            this.selectedDeliveryMethod = deliveryMethod;
            this.selectedDeliveryLocation = deliveryMethod?.deliveryLocations?.[0] ?? undefined;
            this.selectedDeliveryWindow = deliveryMethod?.deliveryWindows?.[0] ?? undefined;
        },
        setSelectedDeliveryLocation(deliveryLocation: DeliveryLocation | null) {
            this.selectedDeliveryLocation = deliveryLocation;
        },
        setSelectedDeliveryWindow(deliveryWindow: DeliveryWindow | undefined) {
            this.selectedDeliveryWindow = deliveryWindow;
        },
        setError(field: string | number, error: string) {
            this.errors[field] = error;
        },
        setErrors(errors: {
            deliveryMethod: string[];
            deliveryWindow: string[];
            deliveryLocation: string[];
        }) {
            this.errors = errors;
        },
        clearAllErrors() {
            this.errors = {
                deliveryMethod: [],
                deliveryWindow: [],
                deliveryLocation: [],
            };
        },
    },
});
