<template>
  <div class="minicart-container">
    <a
      class="page-actions__action nav-profile-menu__trigger"
      :href="props.checkoutUrl"
    >
      <template v-if="bagEmpty">
        <Icon :type="IconTypes.BagEmpty" :size="IconSizes.MiniCartEmpty" />
      </template>
      <template v-else>
        <Icon :type="IconTypes.Bag" :size="IconSizes.MiniCartEmpty" />
        <span class="page-actions__action-amount">{{ totalProductCount }}</span>
      </template>

      <span class="page-actions__action-label u-hide-for-small"
        >Handlekurv</span
      ></a
    >
    <div ref="toast" class="toast">
      <span
        ref="toastMessage"
        class="is-hidden"
        role="alert"
        aria-atomic="true"
        aria-hidden="true"
        aria-live="assertive"
        >{{ errorMessage }}</span
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { CartItem } from "../../services/models/cart.models";
import Icon from "../../components/icon/icon.vue";
import { IconSizes, IconTypes } from "../../components/icon/icon.model";
import { postFormData, get } from "./../../../../helpers/http";
import { displayCartStatus } from "../../../../utils";

const props = defineProps<{
  checkoutUrl: string;
  useMinicartOnMobileString?: string;
}>();

const products = ref([] as Array<CartItem>);
const totalProductCount = ref(0);
const isAnimating = ref(false);
const errorMessage = ref("Det skjedde en feil. Prøv igjen senere" as string);
const toast = ref<HTMLInputElement | null>(null);
const toastMessage = ref<HTMLInputElement | null>(null);

const bagEmpty = computed((): boolean => {
  return totalProductCount.value === 0;
});

const fetchContent = () => {
  return get("/Cart/MiniCartContent");
};

const gtmPush = (gtmString: string): void => {
  // eslint-disable-next-line
  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).dataLayer.push(gtmString);
};

const refreshCart = (): void => {
  //Animate cart
  isAnimating.value = true;
  setTimeout(() => (isAnimating.value = false), 3000);

  fetchContent()
    .then((res) => res.json())
    .then((data) => {
      products.value = data.cartItems;
      totalProductCount.value = data.itemCount;
      localStorage.setItem("totalProductCount", JSON.stringify(data.itemCount));
    });
};

const failureToast = () => {
  //Skriv om til å bruke Vue animasjoner i stedet?
  toastMessage.value?.classList.remove("is-hidden");
  toast.value?.classList.add("is-animating");
  setTimeout(() => {
    toast.value?.classList.remove("is-animating");
    toastMessage.value?.setAttribute("aria-hidden", "true");
  }, 3000);
  setTimeout(() => {
    toastMessage.value?.classList.add("is-hidden");
  }, 4000);
};

const fetchTotalProductCountFromLocalStorage = () => {
  const storedCount = localStorage.getItem("totalProductCount");
  if (storedCount) {
    JSON.parse(storedCount);
    totalProductCount.value = JSON.parse(storedCount);
  }
};

onMounted(() => {
  /**
   * Event listener for the "pageshow" event. Needed so that the total product count is updated when the user navigates back to the page.
   * If not, Edge and Firefox will retrieve the cached value for totalProductCount causing it to show one less if the user for example navigates back right after adding a product.
   * Retrieves the stored total product count from the local storage and updates the value.
   */
  addEventListener("pageshow", fetchTotalProductCountFromLocalStorage);

  fetchContent()
    .then((res) => res.json())
    .then((data) => {
      products.value = data.cartItems;
      totalProductCount.value = data.itemCount;
      localStorage.setItem("totalProductCount", JSON.stringify(data.itemCount));
    });

  const add = document.querySelector("._js-add-to-cart-form");
  add?.addEventListener("submit", (e) => {
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    postFormData("/Cart/AddToCart", formData)
      .then((res) => {
        if (res.status !== 200) {
          throw new Error(res.statusText);
        } else {
          return res.json();
        }
      })
      //TODO: Se om det er denne linjen som skal endres
      .then((res) => gtmPush(res))
      .then(refreshCart)
      .then(displayCartStatus)
      .catch((reason) => {
        errorMessage.value = reason;
        toastMessage.value?.setAttribute("aria-hidden", "false");
        toast.value?.classList.add("error");
        //Animate toast
        failureToast();
        setTimeout(() => {
          toastMessage.value?.classList.remove("error");
        }, 4000);
      });
  });
});

onBeforeUnmount(() => {
  removeEventListener("pageshow", fetchTotalProductCountFromLocalStorage);
});
</script>
