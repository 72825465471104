import $ from "jquery";
window.$ = window.jQuery = $;

import '../Scripts/site.js';
import '../Scripts/global/common/jquery.unobtrusive-ajax.js';
import "lazysizes";
import Mount from "./helpers/mount";

import { loginSessionInit } from "./global/modules/loginSession";
import { oauthFunctions } from "./global/modules/oauth_wp";
import { googleLoginFunctions } from "./global/modules/google_wp";
import Instafeed from "./global/modules/instafeed_wp";
import * as bambuser from "./global/modules/bambuser_wp";
import Menu from "./global/modules/menus_wp";
import * as captcha from "./global/modules/captcha_wp";
import { categoryFunctions, initCategory } from "./global/modules/category_wp";
import { sizeFunctions } from "./global/modules/size";
import * as categoryLayout from "./global/modules/categorylayout_wp";
import { checkoutFunctions, initCheckout } from "./global/modules/checkout_wp";
import CheckStock from "./global/modules/checkstock_wp";
import * as customerClub from "./global/modules/customerclub_wp";
import * as customerService from "./global/modules/customerservice";
import * as delivery from "./global/modules/delivery_wp";
import * as inventory from "./global/modules/inventory_wp";
import { updateFavorites, initFavorites } from "./global/modules/favorite_wp";
import * as filter from "./global/modules/filter_wp";
import { initForms } from "./global/modules/forms_wp";
import * as gtmdata from "./global/modules/gtmdata_wp";
import * as jeansguide from "./global/modules/jeansguide_wp";
import { initToggle } from "./global/modules/toggler_wp";
import Mypage, { myPageFunctions } from "./global/modules/mypage_wp";
import * as popup from "./global/modules/popup_wp";
import Product from "./global/modules/product_wp";
import * as promotions from "./global/modules/promotions_wp";
import Quantity from "./global/modules/quantity_wp";
import Quicksearch from "./global/modules/quicksearch_wp";
import * as slickslide from "./global/modules/slickslide_wp";
import * as videoStructuredData from "./global/modules/structured-video-data_wp";
import * as tabs from "./global/modules/tabs_wp";
import * as teaserblock from "./global/modules/teaserblock_wp";
import * as video from "./global/modules/video_wp";
import VideoBlock from "./global/modules/video-block_wp";
import * as afterpay from "./global/modules/afterpay_wp";
import Chat from "./global/modules/chat_wp";
import apps from "./global/apps.ts";
import DeleteProfile from "./global/modules/mypage_delete_profile";
import ResetPassword from "./global/modules/mypage_reset_password";
import ChangeLoyaltyEmail from "./global/modules/mypage_change_loyalty_email";
import HeaderBanner from "./global/modules/header_banner";
import NewsletterSignupForm from "./global/modules/newsletter_signup_form/newsletter_signup_form";
import HeroBlockHenryVideo from "./global/modules/henry_hero_block_video";
import EpiserverUploadReload from "./global/modules/episerver_upload_reload.ts";

window.voice = {};

window.voice.loginSession = loginSessionInit;
loginSessionInit();
Mount("instafeed-container", Instafeed);
bambuser.init();
Mount("_js-nav-main", Menu);
categoryLayout.init();
customerClub.init();
customerService.init();
filter.init();
window.voice.initForms = initForms;
initForms();
gtmdata.init();
jeansguide.init();
inventory.init();
window.voice.oauth = oauthFunctions;
window.voice.googleLogin = googleLoginFunctions;
Mount("layout-mypage", Mypage);
window.voice.mypage = myPageFunctions;
popup.init();
promotions.init();
Mount("page-actions__search", Quicksearch);
teaserblock.init();
video.init();
Mount("_js-video-block", VideoBlock);
videoStructuredData.init();
initFavorites();
afterpay.init();
Mount("_js-openchat", Chat);
window.voice.updateFavorites = updateFavorites;
window.voice.initToggle = initToggle;
initToggle();
Mount("_js-mypage-delete-profile", DeleteProfile);
Mount("_js-mypage-reset-password", ResetPassword);
Mount("_js-change-loyalty-club-email", ChangeLoyaltyEmail);
Mount("_js-newsletter-signup", NewsletterSignupForm);
Mount("header-banner", HeaderBanner);
Mount("_js-hero-block-henry-video", HeroBlockHenryVideo);
Mount("html", EpiserverUploadReload);

//Kan nok inkluderes kun på /sok?
tabs.init();
//Kan nok inkluderes kun på /handlekurv
Mount("_js-checkout", Quantity);

//Kan nok inkluderes kun på produktsider
slickslide.init();
Mount("product__size", CheckStock);
Mount("_js-product-index", Product);

window.voice.size = sizeFunctions;

//Kan nok inkluderes kun i checkout views
initCheckout();

//Kan nok inkluderes kun i delivery-steg
delivery.init();

initCategory();
window.voice.category = categoryFunctions;

window.voice.captcha = captcha.init;

//Kan nok inkluderes kun i checkout views
window.voice.checkout = checkoutFunctions;

window.voice.pinterest = () => {
    return {
        share: () => {
            window.PinUtils.pinAny();
        },
    };
};

apps.init();

//Remove no-js class from body. This was being done with modernizr before,
//but not much else of modernizr was being used.
document.onreadystatechange = function () {
    if (document.readyState === "complete") {
        document.documentElement.classList.remove("no-js");
        document.documentElement.classList.add("js");
    }

    //Load partial contents
    $(".partialContents").each(function (index, item) {
        const url = $(item).data("url");
        if (url && url.length > 0) {
            $(item).load(url);
        }
    });
};
