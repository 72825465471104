import axios from 'axios';
import {notifyInventory} from './gtmdata_wp';

const validate = inputControl => {
    if (inputControl) {
        const value = inputControl.value;
        if (inputControl.required && value.length === 0) {
            return false;
        }
        if (inputControl.pattern) {
            return RegExp(inputControl.pattern).test(value);
        }
        return true;
    } else {
        return false;
    }
};

const showInventoryNotificationSubscriptionButton = () => {
    let hasAnySoldOutSizes = false;

    document.querySelectorAll('._jsSizeSelector').forEach(i => {
        if (i.classList.contains('_js-inventory-soldout')) {
            hasAnySoldOutSizes = true;
        }
    });

    if (!hasAnySoldOutSizes) {
        $('._js-inventory-nofitication-btn-container').hide();
    }
};

const postNotificationRequest = (emailAddressInput, selectedSizeInput) => {
    const notificationTriggers = document.querySelectorAll('#inventoryNotification .select-size__trigger');

    notificationTriggers.forEach(t => {
        t.removeAttribute('style');
    });

    if (selectedSizeInput && !validate(emailAddressInput)) {
        emailAddressInput.style.borderColor = 'red';
    }

    if (selectedSizeInput && validate(emailAddressInput)) {
        const inventoryNotification = document.getElementById('inventoryNotification');
        const inventoryNotificationSuccess = document.querySelector('.inventory-notification__success');
        emailAddressInput.removeAttribute('style');

        const ajax = axios.create({
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            },
        });

        const inventoryNotificationData = {
            EmailAddress: emailAddressInput.value,
            SkuCode: selectedSizeInput
        };

        ajax.post('/publicapi/Inventory/inventoryNotification', inventoryNotificationData)
            .then(response => {
                inventoryNotification.classList.add('is-success');
                inventoryNotificationSuccess.innerHTML = response.data;
                notifyInventory();
            }).catch(response => {
                inventoryNotification.classList.add('is-failure');
                inventoryNotificationSuccess.innerHTML = response.response.data;
            });
    }
};

const inventory = () => {
    showInventoryNotificationSubscriptionButton();
    const notificationLabel = document.querySelector('._js-inventory-notification-label');
    const notificationButton = document.querySelector('._js-inventory-notification-btn');
    const notificationButtonContainer = document.querySelector('._js-inventory-nofitication-btn-container');
    const notificationEmail = document.getElementById('notificationemail');

    // If there are any sizes in reminder modal, show button to toggle modal
    if (notificationLabel && notificationButton) {
        notificationButton.classList.remove('is-hidden');
        notificationButtonContainer.classList.remove('is-hidden');
    }

    // When hitting enter-key submit form
    if (notificationEmail) {
        notificationEmail.addEventListener('keyup', e => {
            if (e.keyCode === 13) {
                const currentSelectedSize = document.querySelector('.inventory-notification ._jsSizeSelector:checked')[0];
                if (currentSelectedSize) {
                    postNotificationRequest(e.target, currentSelectedSize.value);
                }
            }
        });
    }

    // Post request to be notified
    document.querySelectorAll('._js-post-inventory-notification').forEach(b => {
        b.addEventListener('click', e => {
            const notificationTriggers = document.querySelectorAll('#inventoryNotification .select-size__trigger');
            const currentSelectedSize = document.querySelector('.inventory-notification ._jsSizeSelector:checked');
            const emailAddress = e.target.previousElementSibling;
            if (currentSelectedSize && emailAddress) {
                postNotificationRequest(emailAddress, currentSelectedSize.value);
            }
            if (!currentSelectedSize){
                notificationTriggers.forEach(t => {
                    t.style.borderColor = 'red';
                });
            }
        });
    });

    // Reset modal after fail
    document.querySelectorAll('._js-inventory-failbtn').forEach(b => {
        b.addEventListener('click', e => {
            document.getElementById('inventoryNotification').classList.remove('is-failure');
            document.querySelector('.inventory-notification__success').innerHTML = '';
        });
    });
};

export const init = () => {
    inventory();
};
