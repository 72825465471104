import { defineStore } from "pinia";
import { AddressModelWithoutAnalytics, PlaceholderAddressModel } from "Scripts/global/apps/utils/API/Cart/cartAPISchema";

export const useCustomerInformationStore = defineStore("customerInformation", {
    state: () => ({
        /*
        * fields holds the current value of the customer information form input fields
        */
        fields: {
            email: "" as string | undefined,
            firstName: "" as string | undefined,
            lastName: "" as string | undefined,
            phoneNumber: "" as string | undefined,
            address: "" as string | undefined,
            postalCode: "" as string | undefined,
            city: "" as string | undefined,
            googleAnalyticsDataLayerObject: null,
        } as PlaceholderAddressModel,
        /*
        * errors holds the current error messages for the customer information form input fields
        */
        errors: {
            email: [],
            firstName: [],
            lastName: [],
            phoneNumber: [],
            address: [],
            postalCode: [],
            city: [],
        },
        /*
        * submittedFields holds the last value of the customer information form input fields that were submitted over the API
        */
        submittedFields: {
            email: "",
            firstName: "",
            lastName: "",
            phoneNumber: "",
            address: "",
            postalCode: "",
        } as AddressModelWithoutAnalytics,
        retrievingCity: false,
        errorSummaryVisible: false,
    }),
    actions: {
        setField(field: string | number, value: string | undefined) {
            this.fields[field] = value;
        },
        setFields(fields: PlaceholderAddressModel | null | undefined) {
            if (!fields) {
                this.clearAllFields();
                return;
            }

            this.fields = fields;
        },
        clearAllFields() {
            this.fields = {
                email: "",
                firstName: "",
                lastName: "",
                phoneNumber: "",
                address: "",
                postalCode: "",
                city: "",
            };
        },
        setError(field: string | number, error: any) {
            this.errors[field] = error;
        },
        clearError(field: string | number) {
            this.errors[field] = [];
        },
        clearAllErrors() {
            this.errors = {
                email: [],
                firstName: [],
                lastName: [],
                phoneNumber: [],
                address: [],
                postalCode: [],
                city: [],
            };
        },
        setSubmittedFields(fields: AddressModelWithoutAnalytics) {
            this.submittedFields = fields;
        },
        setErrorSummaryVisible(visible: boolean) {
            this.errorSummaryVisible = visible;
        },
        setRetrievingCity(retrieving: boolean) {
            this.retrievingCity = retrieving;
        },
    },
    getters: {
        fieldsEmpty: (state) => {
            return Object.values(state.fields).some((field) => field === "");
        },
        errorsExist: (state) => {
            return Object.values(state.errors).some(
                (error) => error.length > 0,
            );
        },
    },
});
