import {scrollToTarget} from '../../utils';
import bubbleEvent from '../../helpers/event-bubble';

export default class Product  {
    constructor(element){

        bubbleEvent(element, 'click', 'lipscore-rating-review-link', this.handleLipscoreClick);
    
        bubbleEvent(element, 'click', '_js-extended-details-link', this.handleExtendedDetailsClick);
    
        // Display "No reviews"  if no lipscore-review-content
        window.addEventListener('load', () => {
            setTimeout(() => {
                const reviewContent = Array.from(element.getElementsByClassName('lipscore-review-content'));
                if(reviewContent && reviewContent.length < 1){
                    element.querySelector('._js-lipscore-no-reviews').classList.remove('is-hidden');
                } else {
                    const reviewCount = element.querySelector('.lipscore-rating-review-count').textContent;
                    const reviewListCount = element.querySelector('._js-lipscore-reviews-number');
                    if(reviewCount && reviewListCount){
                        reviewListCount.textContent = reviewListCount.textContent + ' (' + reviewCount + ')';
                    }
                }
            }, 3000);
        });
    
        // Show porterbuddy widget
        const porterbuddyWidget = document.querySelector('#porterbuddy-widget');
        const buyBtn = document.querySelector('._js-add-to-cart');
        if (porterbuddyWidget && buyBtn) {
            window.porterbuddy = {
                token: porterbuddyWidget.dataset.pbToken,
                view: porterbuddyWidget.dataset.pbView,
            };
        }


        // If only-choice (one-size) check radiobutton
        let sizes = Array.from(element.getElementsByClassName('_js-select-size--onesize'));
        sizes = sizes.filter(element => !element.firstElementChild.hasAttribute('disabled'));
        if (sizes.length === 1){
            sizes[0].firstElementChild.setAttribute('checked', 'checked');
        }

    }

    handleLipscoreClick(e) {
        e.preventDefault();

        const lipScoreAccordion = document.getElementById('lipscore-accordion');
        if (lipScoreAccordion && !lipScoreAccordion.hasAttribute('open')) {
            lipScoreAccordion.setAttribute('open', '');
        }
        const id = e.target.getAttribute('href');
        scrollToTarget(id);
    }

    handleExtendedDetailsClick(e) {
        e.preventDefault();

        const extendedDetailsAccordion = document.getElementById('extended-details-accordion');
        if (extendedDetailsAccordion && !extendedDetailsAccordion.hasAttribute('open')) {
            extendedDetailsAccordion.setAttribute('open', '');
        }
        const id = e.target.getAttribute('href');
        scrollToTarget(id);
    }
}
